<template>
  <div>
    <div class="top">
      <img src="../../static/切图文件/footbg.png" alt="">
      <div class="content">
        <p class="ql-editor" style="font-size: 18px;" v-html="bottomLabel1"></p>
      </div>
      <el-button class="btn" type="warning" @click="guanyu">联系我们</el-button>
    </div>
    <div class="down">
      <p class="ql-editor" v-html="bottomLabel2"></p>
    </div>
  </div>
</template>

<script>
import {getMenuList, getContentList} from "../request/index";

export default {
  data() {
    return {
      hezuoid: "", // 合作共赢 关于我们id
      bottomLabel1: '',
      bottomLabel2: ''
    };
  },
  created() {
    let arr = [];
    getMenuList({parentId: 0}).then(res => {
      arr = res.data;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].name == "关于我们") {
          this.hezuoid = arr[i].id;
        }
      }
    });
  },
  mounted() {
    this.getFootInfo();
  },
  methods: {
    getFootInfo() {
      let params = {
        pageNum: 1,
        pageSize: 1,
        type: 2
      };
      getContentList(params).then(res => {
        this.bottomLabel1 = res.rows[0].content;
      });
      params.type = 3;
      getContentList(params).then(res => {
        this.bottomLabel2 = res.rows[0].content;
      });
    },
    guanyu() {
      this.$router.push({
        path: "/about",
        query: {
          id: this.hezuoid
        }
      });

      let href = window.location.hash.slice(2);
      if (href == "about?id=73") {
        document.documentElement.scrollTop = window.pageYOffset = document.body.scrollTop = 0;
      } else {
        this.$router.push({
          path: "/about",
          query: {
            id: this.hezuoid
          }
        });
      }
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.top {
  width: 100%;
  min-width: 1200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.top img {
  width: 100%;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%);
}

.top .content {
  width: 700px;
  position: absolute;
  left: 50%;
  transform: translate(-350px);
  top: 80px;
  color: white;
}

.top .btn {
  position: absolute;
  height: 40px;
  bottom: 50px;
  width: 130px;
  left: 50%;
  transform: translate(-65px);
  background: #fff;
  color: #000;
}

.down {
  width: 100%;
  min-width: 1200px;
  height: 150px;
  background-color: black;
  /* margin-top:0 ; */
}

.p_top {
  color: #aaaaaa;
  font-size: 14px;
  padding-top: 30px;
}

.p_mid {
  color: #aaaaaa;
  font-size: 14px;
  padding-top: 5px;
}

.p_down {
  color: #aaaaaa;
  font-size: 14px;
}

.down {
  color: #aaa;
  font-size: 14px;
  padding-top: 30px;
}
</style>